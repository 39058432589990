// Importing default theme color object to mutate it
import defaultColors from '@elegantstack/solid-ui-theme/src/colors'

// Using tailwind preset colors
import colors from '@elegantstack/solid-ui-theme/src/color-preset'

const customColors = {
  ...defaultColors,
  // Alpha (primary)
  alphaLighter: colors.lightBlue[200],
  alphaLight: colors.lightBlue[300],
  alpha: colors.lightBlue[700],
  alphaDark: colors.lightBlue[800],
  alphaDarker: colors.lightBlue[900],
  // beta (secondary)
  betaLighter: colors.blue[100],
  betaLight: colors.blue[300],
  beta: colors.blue[400],
  betaDark: colors.blue[600],
  betaDarker: colors.blue[800]
}

export default customColors