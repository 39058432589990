import tag from './tag'
import tagWhite from './tag-white'
import tagDark from './tag-dark'
import tagYellow from './tag-yellow'

export default {
  tag,
  'tag-white': tagWhite,
  'tag-dark': tagDark,
  'tag-yellow': tagYellow
}
